import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <p><strong parentName="p">{`Studničky sú obklopené prírodou.`}</strong>{` Všade navôkol hotela sa nachádza les. Kamkoľvek len pozriete očarí vás krásna
príroda, jej pokoj a ničím nerušené ticho. A len 50 m od hotela sa nachádza aj lyžiarsky svah.`}</p>
      <h2>{`Lyžiarsky svah`}</h2>
      <p>{`Lyžiarske stredisko Vernár ponúka skvelú lyžovačku pre začiatočníkov a pokročilých lyžiarov. Celková dĺžka lyžiarskych
svahov dosahuje až 1400 m a k dispozícii sú vám 4 lyžiarske vleky. Svahy ľahkej a stredne ťažkej obtiažnosti sa
nachádzajú v nadmorskej výške 800 až 1000 m. Začínajúci lyžiari môžu využiť aj služby lyžiarskej školy.`}</p>
      <p>{`Viac o lyžiarskom stredisku Vernár Studničky na `}<a parentName="p" {...{
          "href": "https://vernar-studnicky.com/"
        }}>{`vernar-studnicky.com`}</a></p>
      <p>{`Pre našich hotelových hostí je priamo v hoteli dostupná požičovňa lyžiarskych potrieb priamo a vlastná lyžiareň pre
uschovanie vašej výstroje. Vďaka skvelej polohe môžete v lyžiarkach vybehnúť z hotela priamo na svah.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMGBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAc7+6vKdKAK//8QAGxAAAQQDAAAAAAAAAAAAAAAAAQIDBBEAEDL/2gAIAQEAAQUCC5GXKoyXBpzgtoUf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AW1//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AWR//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECERAyQpH/2gAIAQEABj8C78NWU5YZcops/8QAGxABAQABBQAAAAAAAAAAAAAAAREAEDFBUWH/2gAIAQEAAT8hFRHMb2B3zitFNR7IxM//2gAMAwEAAgADAAAAEDT/AP/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EIX/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEWH/2gAIAQIBAT8QrB//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMVGhQf/aAAgBAQABPxC+A+Le5HabNhVw5BsfYL3FVHVRY1ZTWf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/47bbe4dbdc1cedd40d9eb790cca2375a/e93cc/v3f-2.webp 300w", "/static/47bbe4dbdc1cedd40d9eb790cca2375a/b0544/v3f-2.webp 600w", "/static/47bbe4dbdc1cedd40d9eb790cca2375a/68fc1/v3f-2.webp 1200w", "/static/47bbe4dbdc1cedd40d9eb790cca2375a/a2303/v3f-2.webp 1800w", "/static/47bbe4dbdc1cedd40d9eb790cca2375a/4293a/v3f-2.webp 2400w", "/static/47bbe4dbdc1cedd40d9eb790cca2375a/1d906/v3f-2.webp 9587w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/47bbe4dbdc1cedd40d9eb790cca2375a/73b64/v3f-2.jpg 300w", "/static/47bbe4dbdc1cedd40d9eb790cca2375a/3ad8d/v3f-2.jpg 600w", "/static/47bbe4dbdc1cedd40d9eb790cca2375a/6c738/v3f-2.jpg 1200w", "/static/47bbe4dbdc1cedd40d9eb790cca2375a/8b34c/v3f-2.jpg 1800w", "/static/47bbe4dbdc1cedd40d9eb790cca2375a/111a0/v3f-2.jpg 2400w", "/static/47bbe4dbdc1cedd40d9eb790cca2375a/283e2/v3f-2.jpg 9587w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/47bbe4dbdc1cedd40d9eb790cca2375a/6c738/v3f-2.jpg",
              "alt": "v3f 2",
              "title": "v3f 2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABsjuBSNCP/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECFBASE//aAAgBAQABBQLk96iKsiWf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAATECEBEg/9oACAEBAAY/AsMQqfH/xAAeEAEAAgIBBQAAAAAAAAAAAAABABEhMUFRYXGR8f/aAAgBAQABPyFqgivsVHKPiN2vqbLRdM5rvUz1n//aAAwDAQACAAMAAAAQI/8A/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EIF//8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/ELqP/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFRYYGx/9oACAEBAAE/EHPikHVJy7nlQ4fYahR27iHBgXWaExfsV2O0Wo//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/79b047aa7004c6599dca0945a37f4f33/e93cc/IMG_0726-2.webp 300w", "/static/79b047aa7004c6599dca0945a37f4f33/b0544/IMG_0726-2.webp 600w", "/static/79b047aa7004c6599dca0945a37f4f33/68fc1/IMG_0726-2.webp 1200w", "/static/79b047aa7004c6599dca0945a37f4f33/a2303/IMG_0726-2.webp 1800w", "/static/79b047aa7004c6599dca0945a37f4f33/4293a/IMG_0726-2.webp 2400w", "/static/79b047aa7004c6599dca0945a37f4f33/45587/IMG_0726-2.webp 5616w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/79b047aa7004c6599dca0945a37f4f33/73b64/IMG_0726-2.jpg 300w", "/static/79b047aa7004c6599dca0945a37f4f33/3ad8d/IMG_0726-2.jpg 600w", "/static/79b047aa7004c6599dca0945a37f4f33/6c738/IMG_0726-2.jpg 1200w", "/static/79b047aa7004c6599dca0945a37f4f33/8b34c/IMG_0726-2.jpg 1800w", "/static/79b047aa7004c6599dca0945a37f4f33/111a0/IMG_0726-2.jpg 2400w", "/static/79b047aa7004c6599dca0945a37f4f33/a8943/IMG_0726-2.jpg 5616w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/79b047aa7004c6599dca0945a37f4f33/6c738/IMG_0726-2.jpg",
              "alt": "IMG 0726 2",
              "title": "IMG 0726 2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h2>{`Slovenský Raj`}</h2>
      <p>{`Slovenský raj je jednou z najkrajších oblastí Slovenska. Môžete sa prechádzať hlbokými roklinami či zdolať vodopády
pomocou rebríkov. Aké krásy ponúka Slovenský raj v okolí nášho hotela a obce Vernár? Máme pre vás pár tipov:`}</p>
      <p><strong parentName="p">{`Partizánsky bunker.`}</strong>{` Náučná lokalita pozostáva z obnoveného "bunkra" (pozostatok opevnenia z čias II. svetovej vojny),
a z dvoch informačných panelov, ktoré podávajú informácie o výstavbe partizánskych bunkrov počas Slovenského národného
povstania a informácie o priebehu SNP vo Vernári a okolí.`}</p>
      <p><strong parentName="p">{`Dobšinská ľadová jaskyňa.`}</strong>{` Vďaka svojmu významu a unikátnej výzdobe je zaradená od roku 2000 v Zozname svetového
kultúrneho dedičstva UNESCO. Od nášho hotela je vzdialená iba 12km. Svojou dĺžkou a hĺbkou patrí k najväčším ľadovým
jaskyniam v Európe.`}</p>
      <p><strong parentName="p">{`Najkrajší výhľad v Slovenskom raji je bez pochýb Tomášovský výhľad.`}</strong>{` Je z neho pekný výhľad do doliny Bieleho potoka,
na Prielom Hornádu a na Vysoké Tatry. Musíte rátať so stúpaním, avšak hravo sa to dá zvládnuť za približne hodinku.
Patrí medzi najnavštevovanejšie časti národného parku a ak ste tam boli, tak musíte uznať, že zaslúžene.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAFhD5IojVn/xAAaEAADAAMBAAAAAAAAAAAAAAAAAQIQEhMi/9oACAEBAAEFArhFQaC6Jesf/8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BlD//xAAWEQEBAQAAAAAAAAAAAAAAAAAAExH/2gAIAQIBAT8Bpiz/xAAcEAABAwUAAAAAAAAAAAAAAAAAAQKRECExM2H/2gAIAQEABj8CO02Oku5TKyf/xAAbEAADAAIDAAAAAAAAAAAAAAAAAREhgUFh4f/aAAgBAQABPyG1Y2JTirF9HqArOt7Mvm2P/9oADAMBAAIAAwAAABCD7//EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAwEBPxC20//EABcRAAMBAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8QSicP/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBcaH/2gAIAQEAAT8QvYy+rEtQ1UymLOfUushu9cPYs2bncbrelbD/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26136e5cda8530e0b53edd4e0dc7afdb/e93cc/71768192_1166521506879062_5080300695979032576_o.webp 300w", "/static/26136e5cda8530e0b53edd4e0dc7afdb/b0544/71768192_1166521506879062_5080300695979032576_o.webp 600w", "/static/26136e5cda8530e0b53edd4e0dc7afdb/68fc1/71768192_1166521506879062_5080300695979032576_o.webp 1200w", "/static/26136e5cda8530e0b53edd4e0dc7afdb/a2303/71768192_1166521506879062_5080300695979032576_o.webp 1800w", "/static/26136e5cda8530e0b53edd4e0dc7afdb/7c4f2/71768192_1166521506879062_5080300695979032576_o.webp 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26136e5cda8530e0b53edd4e0dc7afdb/73b64/71768192_1166521506879062_5080300695979032576_o.jpg 300w", "/static/26136e5cda8530e0b53edd4e0dc7afdb/3ad8d/71768192_1166521506879062_5080300695979032576_o.jpg 600w", "/static/26136e5cda8530e0b53edd4e0dc7afdb/6c738/71768192_1166521506879062_5080300695979032576_o.jpg 1200w", "/static/26136e5cda8530e0b53edd4e0dc7afdb/8b34c/71768192_1166521506879062_5080300695979032576_o.jpg 1800w", "/static/26136e5cda8530e0b53edd4e0dc7afdb/8fe94/71768192_1166521506879062_5080300695979032576_o.jpg 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/26136e5cda8530e0b53edd4e0dc7afdb/6c738/71768192_1166521506879062_5080300695979032576_o.jpg",
              "alt": "71768192 1166521506879062 5080300695979032576 o",
              "title": "71768192 1166521506879062 5080300695979032576 o",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      